import { Route, Switch } from 'react-router-dom';
import { Home, MyReports } from './pages';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { isIE } from 'react-device-detect';
import usePageTracking from './usePageTracking';
import BottomBar from './components/BottomBar';

const App = () => {
    usePageTracking();

    if (isIE) {
        return (
            <div className="text-center">
                <h2>인터넷 익스플로러는 지원하지 않습니다.</h2>
                <br />
                <h2>크롬이나 엣지등 다른 브라우저를 사용해 주세요.</h2>
            </div>
        );
    }

    return (
        <>
            <Route exact path="/" component={Home} />
            <Switch>
                <Route path="/MyReports" component={MyReports} />
            </Switch>
            <BottomBar />
        </>
    );
};

export default App;
