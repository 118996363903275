// From https://stackoverflow.com/questions/34836500/how-to-set-up-google-analytics-for-react-router.
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (!window.location.href.includes('localhost')) {
//            ReactGA.initialize('UA-185250602-1');
            setInitialized(true);
        }
    }, []);

    useEffect(() => {
        if (initialized) {
//            ReactGA.set({ page: location.pathname + location.search});
//            ReactGA.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};

export default usePageTracking;
