import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Accordion, Button, Form, Card } from 'react-bootstrap';
import ReactSnackBar from 'react-js-snackbar';
import ReactHtmlParser from 'react-html-parser';
import axios from 'axios';

function Home() {
    const [plateId, setPlateId] = useState('');
    const [feedback, setFeedback] = useState('');
    const [output, setOutput] = useState('');
    const [meaningByPos, setMeaningByPos] = useState({});
    const [snackbarShow, setSnackbarShow] = useState(false);
    const [snackbarShowing, setSnackbarShowing] = useState(false);

    const htmlOutput = output;
    const annotatedOutputInHtml = ReactHtmlParser(
        htmlOutput.replace(/\n/g, '<br>')
    );

    useEffect(() => {
    }, []);

    const handlePlateIdChange = (e) => {
        e.preventDefault();
        setPlateId(e.target.value);
    };
    const handleFeedbackChange = (e) => {
        e.preventDefault();
        setFeedback(e.target.value);
    };
    const clearText = (e) => {
        setFeedback('');
        setOutput('');
        setMeaningByPos({});
    };
    const submitFeedback = async (e) => {
        const url = 'https://driverip.hanmesoft.com/server/report.php';
        // The following is to fix the post params not being set as $_POST in php code.
        // Reference: https://github.com/axios/axios/issues/1195.
        const params = new URLSearchParams();
        params.append('plateId', plateId);
        params.append('feedbackText', feedback);

        const res = await axios.post(url, params);
        const data = res.data;
        console.log(data);
    };
    const showSnackbar = () => {
        if (snackbarShowing) {
            return;
        }
        setSnackbarShowing(true);
        setSnackbarShow(true);
        setTimeout(() => {
            setSnackbarShow(false);
        }, 2000);
        setTimeout(() => {
            setSnackbarShowing(false);
        }, 3600);
    };

    return (
        <>
            <div className="container-fluid padding">
                <div className="row title text-center">
                    <div className="col-12 m-0 pb-0">
                        <h1 className="display-4 my-2">
                            <a
                                href="https://driverip.hanmesoft.com"
                                className="text-decoration-none"
                            >
                                운전자 인성평가
                            </a>
                        </h1>
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                    <div className="col-sm-10 col-md-6 col-xl-4 m-0 py-0 px-3">
                        <input
                            type="text"
                            autocomplete="off"
                            className="form-control mb-3 mx-auto"
                            placeholder="신고할 차량 번호"
                            size="12"
                            style={{
                                textAlign: 'center',
                                fontSize: 'x-large',
                                maxWidth: '300px',
                            }}
                            onChange={handlePlateIdChange}
                            value={plateId}
                        />
                        <textarea
                            className="form-control my-2"
                            placeholder="신고할 내용을 여기에 입력하고 '신고하기' 버튼을 누르세요."
                            rows="4"
                            onChange={handleFeedbackChange}
                            value={feedback}
                        />
                        <Button
                            variant="primary"
                            className="m-2"
                            onClick={submitFeedback}
                            disabled={(plateId === '') || (feedback === '')}
                            style={{
                                minWidth: '244px',
                                minHeight: '60px',
                            }}
                        >
                            신고하기
                        </Button>
                        <Accordion>
                            <Button
                                variant="info"
                                className="m-2"
                                onClick={clearText}
                                disabled={feedback === ''}
                            >
                                입력창 지우기
                            </Button>
                            <Accordion.Toggle
                                as={Button}
                                variant="info"
                                className="m-2"
                                disabled={plateId === ''}
                                eventKey="0"
                            >
                                조회하기
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <Card bg="light" className="m-2 my-0">
                                    <Card.Body>
                                        <Form>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Accordion.Collapse>
                        </Accordion>
                        <Link to="/MyReports">
                            내가 한 신고 보기
                        </Link>
                        {output !== '' && (
                            <>
                                <h5 className="mt-2 mb-0">주석달린 문장:</h5>
                                <Card
                                    bg="light"
                                    className="mx-0 my-2 pl-1 text-left"
                                >
                                    <Card.Body className="p-2">
                                        {annotatedOutputInHtml}
                                    </Card.Body>
                                </Card>
                            </>
                        )}
                    </div>
                    <div className="col-sm-1 col-md-3 col-xl-4 m-0 p-0"></div>
                </div>
            </div>
            <ReactSnackBar Icon={<span>📝</span>} Show={snackbarShow}>
                <div className="text-center">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;변환된 텍스트가
                    복사되었습니다.
                </div>
            </ReactSnackBar>
        </>
    );
}

export default Home;
